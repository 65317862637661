import { words, reduce, deburr, capitalize } from 'lodash'

// Allowed prefixes (for properties like '_destroy' or '$type')
const prefixesPattern = new RegExp(/^[$_]/)
// Camel case joining
const camelCasePattern = new RegExp(/([a-z\d])([A-Z])/g)
// Simple words pattern
const wordsPattern = new RegExp(/(v\d+)|([a-z]+)|(\d+)/gi)

const createCompounder = cb => str => {
  const compounded = reduce(words(deburr(str).replace(camelCasePattern, '$1 $2'), wordsPattern), cb, '')

  return `${prefixesPattern.test(str) ? str[0] : ''}${compounded}`
}

export const camelCaseKey = createCompounder(
  (result, word, index) => `${result}${index ? capitalize(word) : word.toLowerCase()}`,
)

export const snakeCaseKey = createCompounder(
  (result, word, index) => `${result}${index ? '_' : ''}${word.toLowerCase()}`,
)
